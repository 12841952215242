<template>
  <vue3-chart-js ref="chart" v-bind="{ ...barChart }" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import { ChartOptions } from '../interfaces/ChartOptions'

export interface HistogramChartItem {
  type: string,
  value: number,
}

interface ItemGroup {
  value: number,
  items: {
    [type: string]: number
  }
}

interface Props {
  tags: string[];
  labels: string[];
  interval: number;
  start: number;
}

@Options({
  props: {
    tags: Array,
    labels: Array,
    interval: Number,
    start: Number,
  },
  components: {
    Vue3ChartJs,
  },
})
export default class HistogramChart extends Vue {
  
  barChart: ChartOptions = {
    type: 'bar',
    options: {
      responsive: true,
      plugins: {
        legend: {
          labels: {
            color: 'aliceblue'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: 'aliceblue'
          },
          grid: {
            color: '#FFF0'
          }
        },
        y: {
          // type: 'logarithmic',
          ticks: {
            color: 'aliceblue',
            precision: 0,
          },
          grid: {
            color: '#FFF3'
          }
        }
      }
    },
    data: {
      labels: [],
      datasets: [],
    },
  }

  colors = ['#002dbf', '#99004d', '#00a69d', '#cc5c00', '#5f009e', '#ad9f00', '#db6393', '#006344', '#bf0000', '#00b000']
  
  events: HistogramChartItem[] = []

  setData(events: HistogramChartItem[]) {
    if (events.length === 0) {
      this.clear()
      return
    }

    this.events = events

    const interval = this.props().interval ?? 1
    const chartData = this.groupData(this.events, interval)

    let tags = this.props().tags
    if (!tags) {
      tags = [...new Set(this.events.map(item => item.type))]
    }

    let labels = this.props().labels
    if (!labels) {
      labels = chartData.map(data => data.value.toString())
    }

    this.barChart.data.labels = labels
    this.barChart.data.datasets = tags.map((type, i) => ({
      label: type,
      data: chartData.map(data => data.items[type] || 0),
      backgroundColor: this.colors[i],
      borderColor: '#000000',
      borderWidth: 0.2,
      stack: 'Stack 1',
    }))

    const chart = this.$refs.chart as any
    chart.update()
  }

  clear() {
    this.barChart.data.labels = []
    this.barChart.data.datasets = []
    const chart = this.$refs.chart as any
    chart.update()
  }

  groupData(data: HistogramChartItem[], interval: number) {
    data.sort((a, b) => a.value - b.value)
    const grouped: ItemGroup[] = []

    const startValue = this.props().start ?? data[0].value

    grouped[0] = { value: startValue, items: {} }
    
    let g = 0
    for (const curr of data) {
      while (grouped[g].value + interval <= curr.value) {
        g++
        grouped[g] = { value: grouped[g - 1].value + interval, items: {} }
      }

      if (!grouped[g].items[curr.type]) {
        grouped[g].items[curr.type] = 0
      }
      grouped[g].items[curr.type] += 1

    }

    return grouped
  }

  props() {
    return this.$props as Props
  }
}
</script>

<style scoped>

</style>
